<template>
  <tabs
    ref="tabbedContentTabs"
    :options="{
      useUrlFragment: false,
      defaultTabHash: targetTabHash ?? defaultTabHash,
    }"
    wrapper-class="tabs-component"
    nav-class="relative z-0 flex max-w-full px-8 rounded-tl-lg rounded-tr-lg sm:px-0 tabs-component-tabs gap-2"
    nav-item-class="tabs-component-tab rounded-tl-lg rounded-tr-lg relative flex-1 overflow-hidden drop-shadow-[0_-3px_4px_rgba(0,0,0,0.1)] bg-bc-dark-blue"
    nav-item-active-class="is-active !bg-white"
    nav-item-disabled-class="is-disabled"
    nav-item-link-class="text-xl font-medium text-center text-white font-figtree tabs-component-tab-a"
    nav-item-link-active-class="is-active !text-bc-dark-blue"
    nav-item-link-disabled-class="is-disabled"
    panels-wrapper-class="px-8 bg-white tabs-component-panels"
    @changed="tabChanged"
  >
    <tab
      panel-class="tabs-component-panel"
      prefix='<span class="block px-4 py-4">'
      suffix="</span>"
      v-for="tab in organism"
      :name="tab.name"
      :id="tab.id"
      :is-disabled="tab.isDisabled"
    >
      <ClientOnly>
        <div
          v-if="variant === 'product-detail'"
          class="relative max-w-3xl mx-auto"
        >
          <slot :name="tab.id" />
          <button
            v-if="hasMultipleTabs"
            class="absolute left-0 inset-y-1/2"
            @click="prevTab(tab.id)"
            title="Previous Tab"
          >
            <ChevronLeftIcon
              class="w-12 h-12 p-2 text-white rounded-full bg-fr-secondary-dark"
              aria-hidden="true"
            />
          </button>
          <button
            v-if="hasMultipleTabs"
            class="absolute right-0 inset-y-1/2"
            @click="nextTab(tab.id)"
            title="Next Tab"
          >
            <ChevronRightIcon
              class="w-12 h-12 p-2 text-white rounded-full bg-fr-secondary-dark"
              aria-hidden="true"
            />
          </button>
        </div>

        <slot v-else :name="tab.id" />
      </ClientOnly>
    </tab>
  </tabs>
</template>

<style>
.tabs-component-tabs {
  @apply flex-col sm:flex-row gap-1 sm:gap-2;
}
</style>
<script setup>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/vue/outline/index.js";
import { Tabs, Tab } from "vue3-tabs-component";
import { ref } from "vue";
const tabbedContentTabs = ref(null);
const neighborhoodDetails = useNeighborhoodDetails();

const props = defineProps({
  organism: Object,
  defaultTabHash: String,
  targetTabHash: String,
  variant: String,
});

const tabChanged = (selectedTab) => {
  neighborhoodDetails.currentTab = selectedTab.tab.computedId;
};

const tabIds = computed(() => {
  return props.organism.map((tab) => tab.id);
});

const nextTab = (tabId) => {
  const currentIndex = tabIds.value.indexOf(tabId);
  const targetIndex =
    currentIndex === tabIds.value.length - 1 ? 0 : currentIndex + 1;
  const target = "#" + tabIds.value[targetIndex];

  tabbedContentTabs.value.selectTab(target);
};

const prevTab = (tabId) => {
  const currentIndex = tabIds.value.indexOf(tabId);
  const targetIndex =
    currentIndex === 0 ? tabIds.value.length - 1 : currentIndex - 1;
  const target = "#" + tabIds.value[targetIndex];

  tabbedContentTabs.value.selectTab(target);
};

const hasMultipleTabs = computed(() => {
  return tabIds.value.length > 1;
});

onMounted(() => {
  window.addEventListener(
    "hashchange",
    () => {
      const urlHash = window.location.hash;
      if (tabbedContentTabs.value) {
        tabbedContentTabs.value.selectTab(urlHash);
      }
    },
    false,
  );
});
</script>
